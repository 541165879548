.metodo {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  min-height: 80vh;
  padding-top: 15vh;
  padding-bottom: 50px;

  width: 100%;
  min-width: 375px !important;
}

.metodo .title {
  font-size: 30px;
  font-weight: 400;
  line-height: 75px;
  text-align: center;
}

.metodo .metodo-main-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  width: 64%;
}

.metodo .sub-title {
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.metodo .main-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: justify;
}

.metodo .text-container {
  position: relative;
  display: inline-block;
}

.metodo .scribble {
  position: absolute;
  width: 150px;
  top: 50%;
  left: 50%;

  border-radius: 60%;
  color: #ffffff00;
  border: 0.5px solid rgb(255, 255, 255);
  transform: translate(-50%, -50%) rotate(-5deg);
}

.modulos {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  min-height: 60vh;
  padding-top: 70px;
  padding-bottom: 50px;

  width: 100%;
  min-width: 375px !important;
}

.modulos .title {
  width: 100%;
  padding: 10px 0px;
  background-color: rgba(11, 3, 253, 1);
  font-size: 25px;
  font-weight: 400;
  text-align: center;
}

.list-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #666;
  position: relative;
  width: 100%;
}

.line {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #666;
  transform: translateY(-50%);
}

.list-item span {
  z-index: 1; /* Ensure the text is above the line */
  padding: 0 5px; /* Add some padding to the sides */
  width: 90%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5em;
  text-align: center;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 1; /* Ensure the arrow is above the line */
}

.list-item-content {
  width: 100%;
  padding: 20px 0px;
}

.list-item-content span {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.list-item-content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: justify;
  width: 80%;
  margin-left: 10%;
}

@media (min-width: 600px) {
  .metodo-main-text {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    height: 300px;
  }

  .metodo-main-text .sub-title {
    width: 45%;
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }

  .metodo-main-text .main-text {
    width: 40%;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: justify;
  }

  .full-box {
    width: 70%;
    min-height: 320px;
  }

  .list-item-content {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .metodo .metodo-main-box {
    width: 85%;
  }

  .modulos {
    width: 85%;
  }

  .list-item-content {
    width: 70%;
    padding: 20px 0px;
  }
}

@media (min-width: 1024px) {
  .metodo .title {
    font-size: 100px;
    font-weight: 300;
    line-height: 75px;
  }

  .metodo .sub-title {
    font-size: 60px;
    font-weight: 400;
    line-height: 65px;
  }

  .metodo-main-text .main-text {
    font-size: 20px;
    line-height: 20px;
  }

  .metodo .metodo-main-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 64%;
    padding-bottom: 50px;
  }

  .metodo .scribble {
    position: absolute;
    width: 270px;
    top: 50%;
    left: 50%;
  }

  .modulos {
    width: 64%;
  }

  .modulos .title {
    width: 100%;
    font-size: 50px;
    font-weight: 400;
    line-height: 65px;
    text-align: center;
  }

  .list-item {
    width: 100%;
  }

  .list-item-content {
    width: 60%;
    padding: 20px 0px;
  }

  .list-item span {
    font-size: 20px;
  }
}

.intro {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  min-height: 90vh;
  padding-top: 15vh;
  padding-bottom: 50px;

  background: #111;
  color: #fff;

  width: 100%;
  min-width: 375px !important;
}

.intro-main-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  width: 64%;
}

.intro-content {
  margin: 0 auto;
  text-align: justify;
}

.intro .title {
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding: 20px 0px;
}

.intro img {
  width: 250px;
}

.intro .text-container {
  position: relative;
  display: inline-block;
}

.intro .scribble {
  position: absolute;
  width: 150px;
  top: 50%;
  left: 50%;

  border-radius: 60%;
  color: #ffffff00;
  border: 0.5px solid rgb(255, 255, 255);
  transform: translate(-50%, -50%) rotate(-5deg);
}

.btn {
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
}

.intro-content .text {
  margin: 10px 0px;
}

@media (min-width: 600px) {
  .intro img {
    width: 40%;
    max-width: 350px;
    margin: 0px;
  }
}

@media (min-width: 768px) {
  .intro-main-box {
    width: 85%;
  }
}

@media (min-width: 1024px) {
  .intro-main-box {
    width: 64%;

    flex-flow: row nowrap;
    align-items: flex-end;
  }

  .intro .scribble {
    width: 360px;
  }

  .intro .title {
    font-size: 70px;
    font-weight: 400;
    line-height: 65px;
    text-align: left;
    padding: 20px 0px;
  }

  .intro img {
    min-width: 45%;
  }

  .intro-content .text {
    font-size: 20px;
  }
}

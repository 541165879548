.footer {
  width: 100%;
  min-width: 375px !important;
  background: url(../img/mobile-bg.png) no-repeat center center;
  background-size: cover;
  align-content: center;
  position: relative;
  overflow: hidden;

  min-height: 85vh;
  padding-top: 15vh;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
}

.footer-main-box {
  width: 64%;
  min-height: 90vh;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.footer-main-text {
  font-size: 15px;
  font-weight: 300;
  text-align: center;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.social-media {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-width: 80%;
  z-index: 2;
  padding: 30px 0px;
}

.footer img {
  position: absolute;
  z-index: 0;
  left: 20%;
  bottom: 20px;
  width: 350px;
}

.footer-main-text .title {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

.social-media svg {
  width: 50px;
}

.text-footer {
  width: calc(100% - 60px);
  background-color: black;
  position: absolute;
  bottom: 0px;
  padding: 10px 30px;

  font-size: 15px;
  font-weight: 200;
  text-align: right;
}

@media (min-width: 450px) {
  .footer img {
    width: 390px;
    left: 25%;
    bottom: 0px;
  }

  .social-media {
    min-width: 80%;
  }
}

@media (min-width: 500px) {
  .footer img {
    width: 390px;
    left: 25%;
    bottom: 0px;
  }

  .social-media {
    min-width: 80%;
  }
}

@media (min-width: 600px) {
  .footer img {
    width: 390px;
    left: 30%;
    bottom: 0px;
  }
}

@media (min-width: 768px) {
  .footer {
    background: url(../img/bg.png) no-repeat center center;
    background-size: cover;
    align-content: center;
    position: relative;

    width: 100%;
    min-height: 85vh;
    padding-top: 15vh;

    justify-content: flex-end;
  }

  .footer-main-box {
    width: 85%;
    min-height: 100vh;
  }

  .footer-main-text {
    width: 50%;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }

  .footer-main-text .title {
    font-size: 35px;
    font-weight: 400;
    text-align: center;
  }

  .social-media {
    width: 80%;
    padding: 30px 0px;
  }

  .social-media svg {
    width: 70px;
  }

  .footer img {
    width: 390px;
    left: 37%;
    bottom: 0px;
  }
}

@media (min-width: 868px) {
  .footer img {
    width: 400px;
    right: 17%;
    bottom: 0px;
  }
}

@media (min-width: 958px) {
  .footer-main-box {
    align-items: flex-end;
  }

  .footer-main-text {
    width: 80%;
  }

  .social-media {
    min-width: 200px;
    width: 40%;
  }

  .social-media svg {
    width: 70px;
  }

  .footer img {
    width: 500px;
    left: 15%;
    bottom: 0px;
  }
}

@media (min-width: 1024px) {
  .footer-main-box {
    width: 64%;
    min-height: 100vh;
  }

  .footer-main-text {
    width: 80%;
    font-size: 20px;
    font-weight: 300;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .footer-main-text .title {
    font-size: 50px;
    font-weight: 400;
    text-align: center;
  }

  .social-media {
    max-width: 300px;
    padding: 30px 10px;
    width: 60%;
  }

  .social-media svg {
    width: 70px;
  }

  .footer img {
    width: 540px;
    left: 0px;
    bottom: 0px;
  }
}

@media (min-width: 1300px) {
  .footer-main-box {
    align-items: flex-end;
  }

  .footer-main-text {
    width: 60%;
    font-size: 20px;
    font-weight: 300;
  }

  .footer-main-text {
    font-size: 30px;
  }

  .footer-main-text .title {
    font-size: 60px;
    font-weight: 400;
    text-align: right;
  }

  .social-media {
    max-width: 450px;
    padding: 40px 10px;
    width: 70%;
  }

  .social-media svg {
    width: 90px;
  }

  .footer img {
    width: 600px;
    left: 0px;
    bottom: 0px;
  }
}

@media (min-width: 1600px) {
  .footer-main-box {
    align-items: flex-end;
  }
  .footer-main-text {
    width: 60%;
    font-size: 20px;
    font-weight: 300;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .footer-main-text {
    font-size: 35px;
  }

  .footer-main-text .title {
    font-size: 70px;
    font-weight: 400;
    text-align: right;
  }

  .social-media {
    max-width: 450px;
    padding: 40px 10px;
    width: 70%;
  }

  .social-media svg {
    width: 100px;
  }

  .footer img {
    width: 900px;
    left: 0px;
    bottom: 0px;
  }
}

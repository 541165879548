.programa {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background: url(../img/mobile-bg.png) no-repeat center center;
  background-size: cover;

  min-height: 90vh;
  padding-top: 15vh;
  padding-bottom: 50px;

  width: 100%;
  min-width: 375px !important;
}

.programa p {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.program-main-box {
  width: 64%;
  min-height: 320px;
}

.program-main-box .title {
  margin-bottom: 20px;

  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
}

.program-main-box .text-container {
  position: relative;
  display: inline-block;
}

.program-main-box .scribble {
  position: absolute;
  width: 120px;
  top: 50%;
  left: 30%;

  border-radius: 60%;
  color: #ffffff00;
  border: 0.5px solid rgb(255, 255, 255);
  transform: translate(-50%, -50%) rotate(-5deg);
}

.programa ul {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  list-style: none;
  padding: 0;
  text-align: left;
}

.programa ul li {
  display: flex;
  flex-flow: row nowrap;
  padding: 15px 0px;
}

.programa ul li svg {
  min-width: 50px;
  padding-right: 10px;
}

@media (min-width: 600px) {
  .programa ul {
    padding-top: 50px;
  }
}

@media (min-width: 768px) {
  .program-main-box {
    width: 85%;
  }

  .programa ul li {
    width: 90%;
    padding: 15px 20px;
  }

  .programa .scribble {
    width: 110px;
    top: 50%;
    left: 30%;
  }

  .programa p {
    font-size: 18px;
    line-height: 20px;
  }
}



@media (min-width: 968px) {
  .program-main-box {
    width: 85%;
  }

  .programa ul {
    width: 100%;
    flex-flow: column nowrap;
  }

  .programa ul div {
    display: flex;
    flex-flow: row nowrap;
  }

  .programa ul li {
    width: 33%;
    padding: 15px 20px;
  }

  .programa .scribble {
    width: 110px;
    top: 50%;
    left: 30%;
  }
}

@media (min-width: 1024px) {

  .program-main-box {
    width: 64%;
  }

  .programa {
    background: url(../img/bg.png) no-repeat center center;
    background-size: cover;
  }

  .programa .title {
    font-size: 70px;
    font-weight: 400;
    line-height: 65px;
    text-align: left;
  }

  .programa .scribble {
    position: absolute;
    width: 250px;
    top: 50%;
    left: 30%;
  }

  .programa p {
    font-size: 20px;
    line-height: 20px;
  }

  .programa ul li {
    width: 29%;
  }
}

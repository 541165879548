.time {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  min-height: 90vh;
  padding-top: 15vh;
}

.time-main-box {
  width: 80%;
  min-height: 320px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.time-main-box .text-container {
  position: relative;
  display: inline-block;
}

.time-main-box .scribble {
  position: absolute;
  width: 200px;
  height: 30px;
  top: 50%;
  left: 50%;

  border-radius: 60%;
  color: #ffffff00;
  border: 0.5px solid rgb(255, 255, 255);
  transform: translate(-50%, -50%) rotate(-5deg);
}

.time .title {
  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
}

.time .sub-title {
  font-size: 25px;
  font-weight: 200;
  line-height: 120px;
  text-align: center;
}

.time a {
  border-radius: 30px;
  border: transparent;
  background-color: rgba(11, 3, 253, 1);
  padding: 10px 15px;
  color: white;

  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-decoration: none; 
  margin-top: 2em;
}

.time .arrow-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 100px;
  overflow: hidden;
  margin: 0px !important;
  bottom: 20px;
}

.time .arrow {
  width: 80px;
  height: 40px;
  margin: 0px !important;
  padding: 0px !important;
  animation: fade 1.5s infinite;
}

.time .arrow:nth-child(2) {
  border-top-color: #ffffff;
  animation-delay: 0.2s;
}

.time .arrow:nth-child(3) {
  border-top-color: #ffffff;
  animation-delay: 0.4s;
}

@media (min-width: 600px) {
  .time-main-box .scribble {
    position: absolute;
    width: 200px;
    height: 30px;
    top: 50%;
    left: 50%;
  }
  .time a {
    width: 40%;
    margin-top: 3em;
  }
}

@media (min-width: 768px) {
  .time .title {
    font-size: 45px;
    font-weight: 400;
    line-height: 60px;
    text-align: center;
  }

  .time .sub-title {
    font-size: 40px;
    font-weight: 200;
    line-height: 300px;
    text-align: center;
  }

  .time-main-box .scribble {
    position: absolute;
    width: 300px;
    height: 40px;
    top: 50%;
    left: 50%;
  }
}

@media (min-width: 1024px) {
  .time .title {
    font-size: 70px;
    font-weight: 400;
    line-height: 80px;
    text-align: center;
  }

  .time .sub-title {
    font-size: 60px;
    font-weight: 200;
    line-height: 300px;
    text-align: center;
  }

  .time-main-box .scribble {
    position: absolute;
    width: 450px;
    height: 70px;
    top: 60%;
    left: 50%;
  }

  .time a {
    width: 40%;
    margin-top: 3em;
  }
}

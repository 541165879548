header {
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  padding: 1em 0px;
  z-index: 2;

  min-height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  border-bottom: 1px solid rgb(255, 255, 255);
}

.logo {
  width: 100px;
  max-height: 35px;
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: display 0.3s ease;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.overlay ul {
  width: 70%;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
}

.overlay li {
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid #666;
  position: relative;


  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
}

.overlay a {
  text-align: center;
  color: white;
  text-decoration: none;
}

.overlay li.active {
  color: white;
}

.overlay li.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: blue;
  position: absolute;
  bottom: 0;
  left: 0;
}

.overlay li:hover::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: blue;
  position: absolute;
  bottom: 0;
  left: 0;
}

.overlay.open {
  display: flex;
}

.close-icon {
  position: absolute;
  top: 1em;
  right: 1em;
  font-size: 25px;
  cursor: pointer;
  color: white;
  border-radius: 50%;
  padding: 5px 11px;
  background-color: transparent;
  border: 0.5px solid rgb(255, 255, 255);
}

.header-main-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.menu {
  display: none;
  width: 75%;
}

.menu ul {
  list-style: none;
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  transition: display 0.3s ease;
}

.menu li {
  padding: 5px 0;
  position: relative;
}

.menu a {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: white;
  text-decoration: none;
}

.menu-icon {
  font-size: 40px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: white;
  padding: 0px;
}

.menu li.active {
  color: white;
}

.menu li.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: blue;
  position: absolute;
  bottom: 0;
  left: 0;
}

.menu .list:hover::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: blue;

  border-bottom: 1px solid #666;
  position: absolute;
  bottom: 0;
  left: 0;
}

.btn-register {
  color: rgba(11, 3, 253, 1) !important;
  background-color: white;
  text-decoration: none;
  border-radius: 20px;
  padding: 10px 20px;
}

.btn-register:hover {
  background-color: rgba(11, 3, 253, 1);
  color: white !important;
}

@media (min-width: 600px) {
}

@media (min-width: 768px) {
  .menu-icon {
    display: none;
  }

  .header-main-box {
    width: 85%;
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    width: 80%;
  }

  .logo {
    width: 90px;
    max-height: 40px;
  }

  .menu a {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  .header-main-box {
    justify-content: space-between;
    width: 64%;
  }

  .menu {
    width: 80%;
  }

  .menu a {
    font-size: 15px;
  }

  .logo {
    width: 100px;
    max-height: 50px;
  }

  .menu ul {
    list-style: none;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    transition: display 0.3s ease;
  }
}

@media (min-width: 1400px) {
  .header-main-box {
    justify-content: space-between;
    width: 64%;
  }

  .menu {
    width: 80%;
    max-width: 1000px;
  }

  .menu a {
    font-size: 20px;
  }

  .logo {
    width: 150px;
    max-height: 70px;
  }
}

.testimonios {
  background: url(../img/mobile-bg.png) no-repeat center center;
  background-size: cover;
  align-content: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 90vh;
  padding-top: 15vh;
  padding-bottom: 50px;
  min-width: 375px !important;
}

.testimonial-main-box {
  width: 64%;
}

.testimonios .title {
  font-size: 30px;
  font-weight: 400;
  line-height: 75px;
  text-align: center;
}

.testimonios .main-text p {
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}

.testimonios .main-text span {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
}

.testimonios-grid {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.testimonio-card {
  position: relative;
  overflow: hidden;
  background-color: #333;
  border-radius: 25px;
  width: 280px;
  min-height: 500px;
  height: auto;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.testimonio-card .iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.testimonio-card iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.profile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 21px;
}

.profile-overlay-of {
  background: url('../img/of-bg.png') no-repeat center center;
  background-size: cover;
}

.profile-overlay-jm {
  background: url('../img/jm-bg.png') no-repeat center center;
  background-size: cover;
}

.profile-overlay-es {
  background: url('../img/es-bg.png') no-repeat center center;
  background-size: cover;
}

.profile-iframe {
  border-radius: 20px;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.profile-details {
  position: absolute;
  bottom: 20px;
  width: 60%;
  left: 20px;
}

.profile-details p {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.profile-header {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.profile-header h2{
  margin: 0px;
}

.flag {
  width: 24px;
  height: auto;
  margin-right: 10px;
}

.testimonios p {
  margin: 5px 0;
}

.play-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: blue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-button svg {
  width: 30px;
  height: 30px;
}


@media (min-width: 600px) {
  .testimonial-main-box {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .testimonios {
    background: url(../img/bg.png) no-repeat center center;
    background-size: cover;
  }

  .testimonial-main-box {
    width: 85%;
  }
}

@media (min-width: 1024px) {
  .testimonial-main-box {
    width: 64%;
  }

  .testimonios .title {
    font-size: 100px;
    font-weight: 300;
    line-height: 75px;
  }

  .testimonios .main-text {
    padding-top: 60px;
  }

  .testimonios .main-text p {
    font-size: 25px;
    line-height: 25px;
  }

  .testimonios .main-text span {
    font-size: 20px;
  }
}

body {
  margin: 0;
  color: #fff;
  background: #000;
}

ul {
  padding-inline-start: 0px;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
span,
button {
  font-family: 'Arboria', sans-serif !important;
}

.App {
  text-align: center;
}

p {
  margin: 0px;
  padding: 1px 0px !important;
}

.main-box {
  width: 80%;
  min-height: 320px;
}

.border-box {
  border: 0.5px solid rgb(255, 255, 255);
  padding: 1px 3px;
}

.line-with-dots {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding-bottom: 10px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: white;
  position: relative;
}

.dot-right {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  right: -5px;
}

.dot-left {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: -5px;
}

.fade-in-text {
  opacity: 0;
  animation: fadeIn 3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}


@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-BookItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-Thin.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Arboria';
  src: url('../fonts/Arboria-ThinItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}
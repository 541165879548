.banner {
  width: 100%;
  min-width: 375px !important;
  min-height: 100vh;
  background: url(../img/mobile-bg.png) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.banner .banner-main-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.banner .banner-circles {
  display: none;
}

.banner p {
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
}

.banner-main-text {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.banner .register-btn {
  border-radius: 30px;
  border: transparent;
  background-color: white;
  padding: 10px 15px;
  color: black;

  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  margin-top: 80px;
}

.banner .arrow-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 100px;
  overflow: hidden;
  margin: 0px !important;
  bottom: 20px;
}

.banner .arrow {
  width: 60px;
  height: 20px;
  margin: 0px !important;
  padding: 0px !important;
  animation: fade 1.5s infinite;
}

.banner .arrow:nth-child(2) {
  border-top-color: #ffffff;
  animation-delay: 0.2s;
}

.banner .arrow:nth-child(3) {
  border-top-color: #ffffff;
  animation-delay: 0.4s;
}

.banner .arrow:nth-child(4) {
  border-top-color: #a5d6a7;
  animation-delay: 0.6s;
}

@media (min-width: 600px) {
  .banner-main-box p {
    font-size: 30px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;

    margin: 10px;
  }

  .banner .register-btn {
    font-size: 18px;
  }
}

@media (min-width: 778px) {
  .banner {
    background: url(../img/bg.png) no-repeat center center;
    background-size: cover;
  }

  .banner .banner-main-box {
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: space-between;
    width: 80%;
    max-width: 1400px;
  }

  .banner-main-text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .banner .arrow-container {
    display: none;
  }

  .banner p {
    font-size: 35px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }

  .banner .register-btn {
    width: 60%;
    font-size: 20px;
    font-weight: 390;
    margin-top: 40px;
  }

  .banner .banner-circles {
    display: block;
    width: 45%;
  }
}

@media (min-width: 868px) {
  .banner p {
    font-size: 42px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
  }

  .banner .register-btn {
    width: 60%;
    font-size: 20px;
    font-weight: 390;
    margin-top: 40px;
  }

  .banner .banner-circles {
    width: 45%;
  }
}

@media (min-width: 968px) {
  .banner p {
    font-size: 50px;
    line-height: 30px;
    text-align: left;
  }

  .banner .register-btn {
    width: 60%;
    font-size: 20px;
    font-weight: 390;
    margin-top: 40px;
  }

  .banner .banner-circles {
    width: 42%;
    max-width: 450px;
  }
}

@media (min-width: 1024px) {
  .banner .banner-main-box {
    width: 64%;
    justify-content: space-around;
  }

  .banner p {
    font-size: 40px;
    line-height: 25px;
  }

  .banner .register-btn {
    width: 60%;
    font-size: 20px;
    font-weight: 390;
  }

  .banner .banner-circles {
    width: 300px;
  }
}

@media (min-width: 1400px) {
  .banner .banner-main-box {
    width: 64%;
    max-width: 1400px;
  }

  .banner p {
    font-size: 55px;
    line-height: 40px;
  }

  .banner .register-btn {
    font-size: 30px;
  }

  .banner .banner-circles {
    width: 380px;
  }
}

@media (min-width: 1800px) {
  .banner p {
    font-size: 75px;
    line-height: 55px;
  }

  .banner .register-btn {
    font-size: 40px;
  }
}
